import {Injectable} from '@angular/core';
import { Roles } from 'src/app/model/variables';

const rol = Roles;

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'sample-page',
        title: 'Inicio',
        type: 'item',
        url: '/inicio',
        classes: 'nav-item',
        icon: 'feather icon-sidebar',
        permiso: [ rol.administrador ],
      },
      {
        id: 'eventos',
        title: 'eventos',
        type: 'collapse',
        icon: 'feather icon-menu',
        permiso: [ rol.administrador ],
        children: [
          {
            id: 'nuevo',
            title: 'Evento nuevo',
            type: 'item',
            url: '/eventos/nuevo',
          },
        ]
      },
    ]
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
