import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './data/guard/auth.guard';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: './view/pages/login/login.module#LoginModule',
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full'
      },
      {
        path: 'inicio',
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule)
      },
      {
        path: 'resultados',
        loadChildren: () => import('./view/pages/resultados/resultados.module').then(module => module.ResultadosModule)
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
