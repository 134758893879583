export enum Estatus {
  antes = 1,
  despues = 2,
  desactivado = 3,
  finalizado = 4,
  inactivo = 5,
  correcto = 6
}


export enum Roles {
  partidoLocal = 1,
  partido = 2,
  oficial = 3,
  administrador = 4,
}

export class Elecciones {
  idEleccion: number;
  idCandidato: string;
  imagen: string;
  nombre: string;
  // organos: Array<Organo>;
}
